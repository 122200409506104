<template>
  <div>
    name, address, email, phone, t-shirt size
    read and agree to a waiver
    type in car insuranse information
    - Are you driving?
      - Make, Model, VIN, Year, Approx/Insured Value, Lic. Plate, [Drivers Lic. Number, Year expires], Add a Driver? [Their license and year],
    
    download docs
    venmo button
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
div {
  border: 1px solid red;
}
</style>